import {
    mapGetters,
    mapActions
} from 'vuex';

export default {
    data() {
        return {
            imageList: [],
            updateImage: [],
            waiting: false,
        }
    },
    computed: {
        ...mapGetters({
            model: 'printlogos/model',
            rules: 'printlogos/rules',
            columns: 'printlogos/columns',
            getForm: 'printlogos/form',
        })
    },
    methods: {
        submit(close = true) {
        this.$refs['form'].validate((valid) => {
            if (valid){
                let formData = new FormData();
                for (const key in this.form) {
                    formData.append(key, this.form[key]);
                }
                this.save(formData)
                    .then(res => {
                        this.$notify({
                            title: 'Успешно',
                            type: "success",
                            offset: 130,
                            message: res.message
                        });
                        this.fetchData();
                        this.parent().listChanged()
                        if (close == true) {
                            this.close();
                        }
                    })
                    .catch(err => {
                        this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                    });
                }
            });
        },
        createImageList(file) {
            this.form.img = file.raw;
            this.form.name = file.raw.name;
            this.form.name = this.form.name.split('.').slice(0, -1).join('.');
            this.imageList = [];
        },
        handleUpdate(obj) {
            this.fetchEmpty();
            this.updateImage = [];
            this.$store.commit('printlogos/SET_MODEL', obj);
            this.plan = JSON.parse(JSON.stringify(this.model));
        },
        fetchUpdate(close = true) {
            this.waiting = true;
            let formData = new FormData();
            if (_.size(this.updateImage) > 0) {
                for (const key in this.updateImage) {
                    if (this.updateImage.hasOwnProperty(key)) {
                        const element = this.updateImage[key];
                        formData.append(`images[${key}]`, element);
                    }
                }
            }
            for (const key in this.plan) {
                formData.append(key, this.plan[key]);
            }
            formData['id'] = this.plan.id;
            this.update(formData)
                .then(res => {
                    this.$notify({
                        title: 'Успешно',
                        type: "success",
                        offset: 130,
                        message: res.message
                    });
                    this.waiting = false;
                    this.fetchData();
                    this.fetchEmpty();
                    this.parent().listChanged()
                    if (close == true) {
                        this.close();
                    }
                })
                .catch(err => {
                    this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                });
        },
        onProg(file){
        },
        updateImageList(file){
            this.updateImage.push(file.raw);
        },
        handleRemove(file) {
            if (file.id) {
                this.deleteImage({ plan_id: this.plan.id, image_id: file.id }).then(res => {
                    this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                }).catch(err => {});
            } else {
                for (var i = 0; i < _.size(this.updateImage); i++) {
                    if (this.updateImage[i].name === file.name) {
                        this.updateImage.splice(i, 1);
                    }
                }

            }
        },
    }
}
